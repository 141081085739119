import React from "react";
import {
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import noop from "lodash/fp/noop";
import size from "lodash/fp/size";
import styled from "styled-components";
import { Box } from "grommet";
import { Flex, Text } from "../../ui";
import { iconProp } from "../../utils/propTypes";
import { GREEN } from "../../utils/theme";
import TraitToggleIcon from "./TraitToggleIcon";
import TraitToggleTooltip from "./TraitToggleTooltip";
import TraitToggleWrapper from "./TraitToggleWrapper";

const isTraitNameTooLong = traitString => size(traitString) > 30;

const SingleLineText = styled(Text)`
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function TraitToggle({
  title = undefined,
  subtitle = undefined,
  color = GREEN,
  toggled,
  icon = undefined,
  onClick = undefined,
  disabled =false,
  center = false,
  bold = false,
  testId = undefined,
}) {
  return (
    <Flex
      w={center ? "fit-content" : 1}
      minWidth={0.5}
      mx={center ? "auto" : undefined}
      justify={center ? "center" : undefined}
      align="center"
      style={{ userSelect: "none" }}
      data-testid={testId}
    >
      <TraitToggleWrapper
        w={1}
        toggled={toggled}
        color={color}
        onClick={disabled ? noop : onClick}
        disabled={disabled}
        center={center}
        tooltip={isTraitNameTooLong(title) ? { theme: "light", html: <TraitToggleTooltip title={title} /> } : undefined}
      >
        <Box direction="row" gap="small">
          {icon && (
            <Box alignSelf="center">
              <TraitToggleIcon icon={icon} color={color} toggled={toggled} />
            </Box>
          )}
          {typeof title === "string" || typeof title === "number" ? (
            <SingleLineText noMargin condensed bold={bold}>
              {title}
            </SingleLineText>
          ) : title}
        </Box>
        {typeof subtitle === "string" || typeof subtitle === "number" ? (
          <Text noMargin condensed style={{ textTransform: "capitalize" }}>
            {subtitle}
          </Text>
        ) : subtitle}
      </TraitToggleWrapper>
    </Flex>
  );
}

TraitToggle.displayName = "TraitToggle";

TraitToggle.propTypes = {
  title: oneOfType([string, object, number]),
  subtitle: node,
  color: string,
  toggled: bool.isRequired,
  icon: iconProp,
  onClick: func,
  disabled: bool,
  center: bool,
  bold: bool,
  testId: string, // Temporary solution, styled components makes it difficult to pass attributes without assuming its a prop
};

export default TraitToggle;
