import castArray from "lodash/fp/castArray";
import compose from "lodash/fp/compose";
import curry from "lodash/fp/curry";
import every from "lodash/fp/every";
import filter from "lodash/fp/filter";
import flatMap from "lodash/fp/flatMap";
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import isEmpty from "lodash/fp/isEmpty";
import map from "lodash/fp/map";
import reject from "lodash/fp/reject";
import some from "lodash/fp/some";
import uniqBy from "lodash/fp/uniqBy";
import { getThemeColor } from "@/cloverleaf-ui/theme";
import { pluckId } from "../../utils";
import { CANDIDATES_SHOWN_TOOLTIP, PARTIAL_SHOWN_TOOLTIP } from "../../utils/strings";
import {
  ASSESSMENT,
  PURPLE,
  RED
} from "../../utils/theme";
import { concatUsersAndCandidates } from "../Sidebar/TeamMembers/TeamVisibility/utils";

export const strengthCategoryMapping = {
  Executing: {
    color: "#7B2481",
    icon: ["far", "wrench"],
  },
  "Strategic Thinking": {
    color: "#419262",
    icon: ["far", "chess-rook"],
  },
  "Relationship Building": {
    color: "#2F6EC6",
    icon: ["far", "handshake-alt"],
  },
  Influencing: {
    color: "#DA792D",
    icon: ["far", "bullhorn"],
  },
  Wisdom: {
    color: ASSESSMENT.VIA.WISDOM,
    icon: ["far", "book-open"],
  },
  Transcendence: {
    color: ASSESSMENT.VIA.TRANSCENDENCE,
    icon: ["far", "sun"],
  },
  Courage: {
    color: ASSESSMENT.VIA.COURAGE,
    icon: ["far", "shield-alt"],
  },
  Temperance: {
    color: ASSESSMENT.VIA.TEMPERANCE,
    icon: ["far", "anchor"],
  },
  Humanity: {
    color: ASSESSMENT.VIA.HUMANITY,
    icon: ["far", "fingerprint"],
  },
  Justice: {
    color: ASSESSMENT.VIA.JUSTICE,
    icon: ["far", "balance-scale"],
  },
};

export const viaStrengthTypes = [
  "Courage",
  "Transcendence",
  "Temperance",
  "Humanity",
  "Justice",
  "Wisdom",
];

export const discColorMapping = {
  D: ASSESSMENT.RED,
  I: ASSESSMENT.YELLOW,
  S: ASSESSMENT.GREEN,
  C: ASSESSMENT.BLUE,
};

export const discIconMapping = {
  D: ["far", "bolt"],
  I: ["far", "magnet"],
  S: ["far", "mountain"],
  C: ["far", "ruler-triangle"],
};

export const HbdiColorMapping = {
  Analytical: ASSESSMENT.BLUE,
  Practical: ASSESSMENT.GREEN,
  Relational: ASSESSMENT.RED,
  Innovative: ASSESSMENT.YELLOW,
};

export const HbdiIconMapping = {
  Analytical: ["far", "chart-mixed"],
  Practical: ["far", "alarm-clock"],
  Relational: ["far", "hands-holding-heart"],
  Innovative: ["far", "lightbulb-on"],
};

export const InsightsDiscoveryColorMapping = {
  "Cool Blue Energy": ASSESSMENT.BLUE,
  "Earth Green Energy": ASSESSMENT.GREEN,
  "Sunshine Yellow Energy": ASSESSMENT.YELLOW,
  "Fiery Red Energy": ASSESSMENT.RED,
};

export const InsightsDiscoveryIconMapping = {
  "Cool Blue Energy": ["far", "chart-mixed"],
  "Earth Green Energy": ["far", "alarm-clock"],
  "Sunshine Yellow Energy": ["far", "lightbulb-on"],
  "Fiery Red Energy": ["far", "hands-holding-heart"],
};

export const filterUsersWithoutAssessment = curry((users, assessment) =>
  filter(user => isEmpty(user.scores[assessment.toLowerCase()]), users));

export const getUsersWithoutRequiredAssessments = (users, requiredAssessments) => compose(
  uniqBy("id"),
  flatMap(filterUsersWithoutAssessment(users)),
  castArray,
)(requiredAssessments);

const getDiscTraits = get("disc.traits");

export const itemHasDisc = ({ scores }) => !isEmpty(getDiscTraits(scores));

const getIncludedIds = compose(
  pluckId,
  filter("isCandidate"),
);

export const formatVisibility = (includedMembers = [], excludedMembers = []) => {
  const included = getIncludedIds(includedMembers);
  const excluded = pluckId(excludedMembers);

  return { included, excluded };
};

export const updateTeamUsers = curry((included, excluded, team) => {
  const excludedIds = new Set(excluded);

  // Candidates are excluded, unless they are found in the included list
  (team?.candidates || []).forEach(candidate => {
    if (!included.includes(candidate.id)) {
      excludedIds.add(candidate.id);
    }
  });

  // Concat users and candidates on this team
  const allMembers = concatUsersAndCandidates(team);

  // Remove all excluded members
  const visibleUsers = allMembers.filter(user => user && !excludedIds.has(user.id));

  // Merge all visible users on the team under the `user` key
  return { ...team, users: visibleUsers };
});

// TODO: Store this in redux?
export const TEAM_VISIBILITY_STATUS = {
  CANDIDATE: "Candidate",
  PARTIAL: "Partial",
  PRISTINE: "Pristine",
};

export const containsCandidate = some(["isCandidate", true]);
export const containsCandidateMembers = every(["isCandidate", true]);

export const getVisibilityStatus = (includedMembers = [], excludedMembers = []) => {
  if (containsCandidate(includedMembers)) {
    return TEAM_VISIBILITY_STATUS.CANDIDATE;
  }

  if (!containsCandidateMembers(excludedMembers) && !isEmpty(excludedMembers)) {
    return TEAM_VISIBILITY_STATUS.PARTIAL;
  }

  return TEAM_VISIBILITY_STATUS.PRISTINE;
};

export const getStatusColor = (includedMembers = [], excludedMembers = []) => {
  const visiblityStatus = getVisibilityStatus(includedMembers, excludedMembers);

  if (visiblityStatus === TEAM_VISIBILITY_STATUS.CANDIDATE) {
    return getThemeColor("purple600");
  }

  if (visiblityStatus === TEAM_VISIBILITY_STATUS.PARTIAL) {
    return getThemeColor("red500");
  }

  return getThemeColor("grey700");
};

export const getNotificationColor = (includedMembers = [], excludedMembers = []) => {
  const excludedMembersWithoutCandidates = reject(["isCandidate", true], excludedMembers);

  if (containsCandidate(includedMembers) || !isEmpty(excludedMembersWithoutCandidates)) {
    return RED;
  }

  if (isEmpty(excludedMembersWithoutCandidates)) {
    return PURPLE;
  }

  return undefined;
};

export const getStatusTooltip = ({ includedMembers = [], excludedMembers = [] } = {}, pristineTooltip) => {
  const visibilityStatus = getVisibilityStatus(includedMembers, excludedMembers);

  if (visibilityStatus === TEAM_VISIBILITY_STATUS.CANDIDATE) {
    return { title: CANDIDATES_SHOWN_TOOLTIP, position: "bottom" };
  }

  if (visibilityStatus === TEAM_VISIBILITY_STATUS.PARTIAL) {
    return { title: PARTIAL_SHOWN_TOOLTIP, position: "bottom" };
  }

  return pristineTooltip;
};

export const formatDescriptionsToTabs = flow(
  getOr([], "descriptions"),
  map(({ label, message }) => ({ button: label, content: message })),
);

export const formatDescriptionsToTabsWithContentManager = (object, wrapContent) => (
  (object.descriptions || []).map((info) => ({
    button: info.label,
    content: wrapContent(info)
  }))
);

export const formatPremiumDescriptionsToTabs = (lockedLabel, traits) => flow(
  getOr([], "descriptions"),
  map(({ label, message, isPremium }) => ({
    button: label,
    content: message,
    isLocked: isPremium && !message,
    lockedLabel,
  })),
)(traits);

// We can create a team, if the team is org owned and we have the "feature" for this org view
// or the team is not org owned
export const getCanCreateTeam = (currentTeam, queryData) => {
  if (!currentTeam?.organization?.id) {
    return true;
  }

  const hasConfig = getOr(false, "check.canViewCreateOrganizationTeam", queryData);

  return hasConfig;
};
