import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { openModal } from "../../modules/ui";
import { EVENT } from "../utils/constants";
import withProps from "./withProps";

const withUserStatsCard = Component => compose(
  connect(null, { openModalProp: openModal }),
  withProps((props) => {
    const {
      openModalProp,
      userStatsCardData = {},
      user = {},
      assessment,
      trackingData,
    } = props;

    return {
      onClick: (event) => {
        if (props.onClick) {
          props.onClick(event);
        }

        return openModalProp({
          modalType: "UserStatsCard",
          modalData: {
            userId: user.id,
            initialScoreType: assessment,
            trackingData: {
              ...trackingData,
              component: EVENT.ContentViewed.props.component.baseballCard,
              target: EVENT.ContentViewed.props.target.teammate,
              action: EVENT.ContentViewed.props.action.open,
            },
            ...userStatsCardData,
          },
        });
      },
    };
  }),
)(Component);

export default withUserStatsCard;
